jQuery.fn.load = function(callback){ $(window).on("load", callback) };

var ledennetURL = '/members';
var sts         = sts || {};
sts.version     = '2.0.9';
sts.multisite   = '1';
sts.client      = 'NVLF';
sts.url         = 'https://www.nvlf.nl';

$(function () {
    sts.language = $("meta[name=language]").attr("content");
    // Start the ajax call for the ajaxStop
    $.ajax({url: '/empty.htm'});

    CleanUp.removeEmptyElements({
        ignore : ['a:not(.removeIfEmpty)', 'img', 'br', 'p', 'script', 'input', 'textarea', '.fa', 'iframe', 'embed', 'object', 'svg', 'hr', '.g-recaptcha'],
        logging: false
    });

    Translations.translate('definitions');

    // Set value of refresh captcha button
    if ($('.form.variant1').length !== 0 || $('#input_container_refreshcaptchaimage input').length !== 0) {
        $(".form.variant1 .captcha_button input, #input_container_refreshcaptchaimage input").val('');
    }

    // Language switch
    $("a.changeLanguageLink").on("click", function () {
        var language = $(this).attr("data-language");
        localStorage.setItem("CCIGroepSTSLanguage", language);
    });

    var CCIGroepSTSLanguage = $("meta[name=language]").attr("content");
    if (CCIGroepSTSLanguage) {
        localStorage.setItem("CCIGroepSTSLanguage", CCIGroepSTSLanguage);
    }

    scrollAnchors();

});

function scrollAnchors() {
    var anchors = [];

    $('a.cciMceAnchor').each(function () {
        anchors.push($(this).attr('id'));
    });

    anchors.forEach(function (anchor) {
        var selector = 'a[href="#' + anchor + '"]';
        var $html    = $('html,body');
        $(selector)
            .addClass('linkToAnchor')
            .on('click', function (e) {
                e.preventDefault();
                var headerHeight = $('header:first').height();
                $html.animate({
                    scrollTop: ($('#' + anchor).offset().top) - (headerHeight)
                }, 500);
            });
    });
}

$(document).ajaxStop(function () {
    // Translate the content of the controls that don't have a callbackfunction
    Translations.translate('definitions');
    $(document).foundation('equalizer', 'reflow');
    $(".fa").each(function () {
        if (!$(this).is('[class*="fa-"]')) {
            $(this).hide();
        }
    });
});

// Asynchroon script
function contentLoader(id, data) {
    var scopeSelector = "#" + id;
    $(scopeSelector).hide();
    $(scopeSelector).html(data);
    // De translate functie hieronder is waarschijnlijk niet nodig omdat deze ook in de ajaxStop zit. Dit veroorzaakt mogelijk problemen.
    //Translations.translate('definitions', scopeSelector);
    $(scopeSelector).show();
}

// Find empty elements inside the nodes that have the data-watch-empty attribute but ignore if data-watch-empty='ignore', if empty then remove this elements
var CleanUp = (function () {

    var ignoreElements;
    var logging;

    var removeEmptyElements = function (cfg) {
        cfg            = cfg || {};
        ignoreElements = cfg.ignore || ['img', 'br'];
        logging        = cfg.logging || false;
        if (logging) {
            $('#src').val($('body').html());
        }
        $('body').find('[data-remove-if-empty]').each(function () {
            searchElementsToRemove($(this));
            if (checkIfRemoveAllowed($(this))) {
                removeElements($(this));
            }
        });
        if (logging) {
            $('#dst').val($('body').html());
        }
    };

    var checkIfRemoveAllowed = function (that) {
        var i;
        for (i = 0; i < ignoreElements.length; i++) {
            if (that.find(ignoreElements[i]).length !== 0) {
                return false;
            }
        }
        return true;
    };

    // Find children inside the nodes that have the data-remove-if-empty attribute -- find the deepest child
    var searchElementsToRemove = function (p) {
        p.children().each(function () {
            if (p.attr('data-remove-if-empty') !== 'ignore') {
                searchElementsToRemove($(this));
                if (checkIfRemoveAllowed($(this))) {
                    removeElements($(this));
                }
            }
        });
    };

    // Find empty elements inside the nodes that have the data-remove-if-empty attribute but ignore if data-remove-if-empty='false'
    var removeElements = function (p) {
        if (p.attr('data-remove-if-empty') !== 'ignore') {
            var remove = true;
            var i;
            for (i = 0; i < ignoreElements.length; i++) {
                if (p.is(ignoreElements[i])) {
                    remove = false;
                    if (logging) {
                        console.log('element: ', ignoreElements[i], ' ignored');
                    }
                }
            }
        }
        if (remove === true) {
            var e = $.trim(p.text()).replace(/(\r\n|\n|\r)/gm, "").replace(/ /g, '').length;
            if (e === 0) {
                var ch = p[0].querySelectorAll("[data-remove-if-empty=ignore]");
                if (ch.length === 0) {
                    p.remove();
                }
            }
        }
    };
    return {
        removeEmptyElements: removeEmptyElements
    }
})();