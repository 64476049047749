 (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
  (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
  m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
  })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

  ga('create', 'UA-90443449-1', 'auto');
  ga('send', 'pageview');


     window.onload = function() {
         if (window.jQuery) {
             $(document).ready(function () {
                 if ($('#LIVITS_ID_5457349139724').length) {
                     $('#LIVITS_ID_5457349139724').focusout(function() {
                         var email = $('#LIVITS_ID_2086194063360').val();
                         var emailVerificatie = $('#LIVITS_ID_5457349139724').val();

                         if (email != emailVerificatie) {
                             alert('e-mail is niet gelijk aan elkaar.')
                         }
                     });
                 }
                 if ($('#Block_AddFunction_textBoxFunction').length) {
                     var functie = $('#Block_AddFunction_textBoxFunction').val();
                     if (functie == '') {
                         //$('.CrmSiteProfileMutationsAddFunctionRenderControl_functie').show();
                         $('#Block_AddFunction_textBoxFunction').val('Niet bekend');
                     }
                 }
                 if ($('#Block_ChangeFunction_textBoxFunction').length) {
                     var functie = $('#Block_ChangeFunction_textBoxFunction').val();
                     if (functie == '') {
                         //$('.CrmSiteProfileMutationsEditFunctionRenderControl_functie').show();
                         $('#Block_ChangeFunction_textBoxFunction').val('Niet bekend');
                     }
                 }
             });

             $(document).ajaxStop(function() {
                if ($('.SNEditProfileSiteProfielViaForm .SN_MedewerkerData_Functie_Label.Employee')) {
                    $('.SNEditProfileSiteProfielViaForm .SN_MedewerkerData_Functie_Label.Employee').each(function() {
                        $(this).parent().remove();
                    });
                }
            });
         }
     };
